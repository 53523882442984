@charset "UTF-8";
.canopy__modal__overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999999;
}

.canopy__modal__close {
  background: 0 0;
  border: 0;
  margin-left: auto !important;
}

.canopy__modal__container {
  border-radius: 4px;
  box-sizing: border-box;
  width: 360px;
  display: flex;
  flex-direction: column;
}

.canopy__modal__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.canopy__modal__title {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.25;
  color: #00449e;
  box-sizing: border-box;
}

.canopy__modal__close:before {
  content: "✕";
}

.canopy__modal__content {
  background-color: transparent;
  height: 536px;
  margin-top: 1rem;
  margin-bottom: 2rem;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.8);
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
}

.canopy__modal__btn {
  font-size: 0.875rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background-color: #e6e6e6;
  color: rgba(0, 0, 0, 0.8);
  border-radius: 100rem;
  border-style: none;
  border-width: 0;
  cursor: pointer;
  -webkit-appearance: button;
  text-transform: none;
  overflow: visible;
  line-height: 1.15;
  margin: 0;
  will-change: transform;
  -moz-osx-font-smoothing: grayscale;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transform: translateZ(0);
  transition: transform 0.25s ease-out;
}

.canopy__modal__btn:focus,
.canopy__modal__btn:hover {
  transform: scale(1.05);
}

.canopy__modal__btn-primary {
  background-color: #00449e;
  color: #fff;
}

@-webkit-keyframes mmfadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes mmfadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes mmfadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes mmfadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.canopy-modal {
  display: none;
}

.canopy-modal.is-open {
  display: block;
}

.canopy-modal[aria-hidden=false] .canopy__modal__container,
.canopy-modal[aria-hidden=false] .canopy__modal__overlay {
  -webkit-animation: mmfadeIn 0.3s cubic-bezier(0, 0, 0.2, 1);
          animation: mmfadeIn 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.canopy-modal[aria-hidden=true] .canopy__modal__container,
.canopy-modal[aria-hidden=true] .canopy__modal__overlay {
  -webkit-animation: mmfadeOut 0.3s cubic-bezier(0, 0, 0.2, 1);
          animation: mmfadeOut 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.canopy-modal .canopy__modal__container,
.canopy-modal .canopy__modal__overlay {
  will-change: transform;
}

.canopy__button {
  color: #fff;
  background: #3f5dff;
  padding: 0.5rem 1rem;
  display: inline-block;
  border-radius: 4px;
  transition-duration: 0.25s;
  border: none;
  font-size: 14px;
  font-weight: 500;
  text-decoration: none;
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
}